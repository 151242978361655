<template>
  <div class="m-2">
    <arq-previews-page />
    <h1>Solicitud de servicios</h1>
    <validation-observer ref="simpleRules">
      <form
        class="col col-md-6 col-sm-2 mx-auto  my-4"
      >
        <div class="card p-2">
          <div
            v-for="(f, index) in form"
            :key="index"
          >
            <label :for="f.key">
              {{ f.label }}
            </label>
            <!-- <div v-if="!f.key.includes('secondLastName')">
              <b-form-input
                :id="f.key"
                v-model="data[f.key]"
                :name="f.key"
                :state="errors.length > 0 ? false:null"
                :type="f.type"
                :required="f.required"
                :disabled="f.disabled"
              />
            </div> -->
            <div v-if="types.includes(f.type)">
              <div v-if="f.key.includes('identification')">
                <validation-provider
                  #default="{ errors }"
                  :name="f.label"
                  rules="required"
                >
                  <b-form-input
                    :id="f.key"
                    v-model="data[f.key]"
                    :name="f.key"
                    :state="errors.length > 0 ? false:null"
                    :type="f.type"
                    :required="f.required"
                    :disabled="f.disabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div>

                <div v-if="f.key.includes('nummaxcuotas')">
                  <b-form-input
                    :id="f.key"
                    v-model="data[f.key]"
                    :name="f.key"
                    :type="f.type"
                    :required="f.required"
                    :disabled="validateSaldo"
                    @input="keyValidateDues(data[f.key])"
                  />
                </div>
                <div v-if="f.key.includes('cuotainicial')">
                  <b-form-input
                    :id="f.key"
                    v-model="data[f.key]"
                    :name="f.key"
                    :type="f.type"
                    :required="f.required"
                    :disabled="validateSaldo"
                    @input="keyValidateBalanceMin(data[f.key])"
                  />
                </div>
              </div>
              <div
                v-if="!f.key.includes('nummaxcuotas') && !f.key.includes('identification') && !f.key.includes('cuotainicial')
                  && !f.key.includes('secondName') && !f.key.includes('secondLastName')
                "
              >
                <validation-provider
                  #default="{ errors }"
                  :name="f.label"
                  rules="required"
                >
                  <b-form-input
                    :id="f.key"
                    v-model="data[f.key]"
                    :name="f.key"
                    :type="f.type"
                    :state="errors.length > 0 ? false:null"
                    :required="f.required"
                    :disabled="f.disabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div v-else>
                <div v-if="!f.key.includes('identification')">

                  <b-form-input
                    :id="f.key"
                    v-model="data[f.key]"
                    :name="f.key"
                    :type="f.type"
                    :required="f.required"
                    :disabled="f.disabled"
                  />
                </div>
              </div>
            </div>
            <div v-if="f.type === 'select-contract'">
              <b-form-select
                :id="f.key"
                v-model.number="data[f.key]"
                value-field="idProducto"
                text-field="descservicio"
                :options="contratos"
                @input="getCarteraSimulacro(data[f.key])"
              />
              <div class="my-2">
                <span>Saldo Cartera:</span>
                {{ consultaCartera.balance | toCurrency }}
              </div>
            </div>
            <div v-if="f.type === 'select-document-type'">
              <validation-provider
                #default="{ errors }"
                :name="f.label"
                rules="required"
              >
                <b-form-select
                  :id="f.key"
                  v-model.number="data[f.key]"
                  value-field="id"
                  text-field="description"
                  :state="errors.length > 0 ? false:null"
                  :options="documentTypes"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div v-if="f.type === 'select-person-types'">
              <validation-provider
                #default="{ errors }"
                :name="f.label"
                rules="required"
              >
                <b-form-select
                  :id="f.key"
                  v-model.number="data[f.key]"
                  value-field="id"
                  :state="errors.length > 0 ? false:null"
                  text-field="desc"
                  :options="persontypes"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div v-if="f.type === 'select-genero'">
              <validation-provider
                #default="{ errors }"
                :name="f.label"
                rules="required"
              >
                <b-form-select
                  :id="f.key"
                  v-model.number="data[f.key]"
                  value-field="id"
                  text-field="desc"
                  :state="errors.length > 0 ? false:null"
                  :options="genretypes"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <b-button
            :disabled="validateButton"
            variant="primary mt-3"
            @click="confirm()"
          >
            Confirmar
          </b-button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      data: {},
      form: [
        {
          label: 'Tipo De Documento',
          key: 'documentType',
          type: 'select-document-type',
          required: true,
        },
        {
          label: 'Identificación',
          key: 'identification',
          type: 'number',
          required: true,
        },
        {
          label: 'Primer Nombre',
          key: 'firstName',
          type: 'text',
          required: true,
        },
        {
          label: 'Segundo Nombre',
          key: 'secondName',
          type: 'text',
          required: true,
        },
        {
          label: 'Primer Apellido',
          key: 'firstLastName',
          type: 'text',
          required: true,
        },
        {
          label: 'Segundo Apellido',
          key: 'secondLastName',
          type: 'text',
          required: true,
        },
        {
          label: 'Tipo de Persona',
          key: 'typesPerson',
          type: 'select-person-types',
          required: true,
        },
        {
          label: 'Dirección',
          key: 'direccion',
          type: 'text',
          required: true,
        },
        {
          label: 'Teléfono',
          key: 'phone',
          type: 'number',
          required: true,
        },
        {
          label: 'Celular',
          key: 'phoneNumber',
          type: 'number',
          required: true,
        },
        {
          label: 'Correo',
          key: 'email',
          type: 'email',
          required: true,
        },
        {
          label: 'Género',
          key: 'genero',
          type: 'select-genero',
          required: true,
        },
      ],
      types: [
        'text',
        'number',
        'email',
        'password',
        'search',
        'url',
        'tel',
        'date',
        'time',
        'range',
        'color',
      ],
      validateButton: false,
      documentTypes: [],
      persontypes: [],
      genretypes: [],
      observacion: '',
    }
  },
  created() {
    this.getTipoDocumentos()
    this.getPersonTypes()
    this.getGenreTypes()
  },
  methods: {
    getTipoDocumentos() {
      axios.get('/app/client/documenttypes')
        .then(res => {
          this.documentTypes = res.data.list
        })
    },
    getPersonTypes() {
      axios.get('/app/client/persontypes')
        .then(res => {
          this.persontypes = res.data.list
        })
    },
    getGenreTypes() {
      axios.get('/app/client/genretypes')
        .then(res => {
          this.genretypes = res.data.list
        })
    },
    confirm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const separador = '-'
          if (this.data.secondName === undefined && this.data.secondLastName === undefined) {
            this.observacion = this.data.documentType + separador + this.data.identification + separador + this.data.firstName + separador
            + this.data.firstLastName + this.data.typesPerson
            + separador + this.data.direccion + separador + this.data.phone + separador + this.data.phoneNumber + separador + this.data.email + separador
            + this.data.genero
          } else {
            this.observacion = this.data.documentType + separador + this.data.identification + separador + this.data.firstName + separador
            + this.data.secondName + separador + this.data.firstLastName + separador + this.data.secondLastName + separador + this.data.typesPerson
            + separador + this.data.direccion + separador + this.data.phone + separador + this.data.phoneNumber + separador + this.data.email + separador
            + this.data.genero
          }
          const form = {
            idService: -1,
            email: this.data.email,
            observacion: this.observacion,
          }
          this.sendProcedure(form)
        }
      })
    },
    async sendProcedure(formData) {
      return new Promise((resolve, reject) => {
        axios.post('/app/procedure/solicitudDeServicios', formData)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
}
</script>
