var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-2"},[_c('arq-previews-page'),_c('h1',[_vm._v("Solicitud de servicios")]),_c('validation-observer',{ref:"simpleRules"},[_c('form',{staticClass:"col col-md-6 col-sm-2 mx-auto  my-4"},[_c('div',{staticClass:"card p-2"},[_vm._l((_vm.form),function(f,index){return _c('div',{key:index},[_c('label',{attrs:{"for":f.key}},[_vm._v(" "+_vm._s(f.label)+" ")]),(_vm.types.includes(f.type))?_c('div',[(f.key.includes('identification'))?_c('div',[_c('validation-provider',{attrs:{"name":f.label,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":f.key,"name":f.key,"state":errors.length > 0 ? false:null,"type":f.type,"required":f.required,"disabled":f.disabled},model:{value:(_vm.data[f.key]),callback:function ($$v) {_vm.$set(_vm.data, f.key, $$v)},expression:"data[f.key]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('div',[(f.key.includes('nummaxcuotas'))?_c('div',[_c('b-form-input',{attrs:{"id":f.key,"name":f.key,"type":f.type,"required":f.required,"disabled":_vm.validateSaldo},on:{"input":function($event){return _vm.keyValidateDues(_vm.data[f.key])}},model:{value:(_vm.data[f.key]),callback:function ($$v) {_vm.$set(_vm.data, f.key, $$v)},expression:"data[f.key]"}})],1):_vm._e(),(f.key.includes('cuotainicial'))?_c('div',[_c('b-form-input',{attrs:{"id":f.key,"name":f.key,"type":f.type,"required":f.required,"disabled":_vm.validateSaldo},on:{"input":function($event){return _vm.keyValidateBalanceMin(_vm.data[f.key])}},model:{value:(_vm.data[f.key]),callback:function ($$v) {_vm.$set(_vm.data, f.key, $$v)},expression:"data[f.key]"}})],1):_vm._e()]),(!f.key.includes('nummaxcuotas') && !f.key.includes('identification') && !f.key.includes('cuotainicial')
                && !f.key.includes('secondName') && !f.key.includes('secondLastName')
              )?_c('div',[_c('validation-provider',{attrs:{"name":f.label,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":f.key,"name":f.key,"type":f.type,"state":errors.length > 0 ? false:null,"required":f.required,"disabled":f.disabled},model:{value:(_vm.data[f.key]),callback:function ($$v) {_vm.$set(_vm.data, f.key, $$v)},expression:"data[f.key]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_c('div',[(!f.key.includes('identification'))?_c('div',[_c('b-form-input',{attrs:{"id":f.key,"name":f.key,"type":f.type,"required":f.required,"disabled":f.disabled},model:{value:(_vm.data[f.key]),callback:function ($$v) {_vm.$set(_vm.data, f.key, $$v)},expression:"data[f.key]"}})],1):_vm._e()])]):_vm._e(),(f.type === 'select-contract')?_c('div',[_c('b-form-select',{attrs:{"id":f.key,"value-field":"idProducto","text-field":"descservicio","options":_vm.contratos},on:{"input":function($event){return _vm.getCarteraSimulacro(_vm.data[f.key])}},model:{value:(_vm.data[f.key]),callback:function ($$v) {_vm.$set(_vm.data, f.key, _vm._n($$v))},expression:"data[f.key]"}}),_c('div',{staticClass:"my-2"},[_c('span',[_vm._v("Saldo Cartera:")]),_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.consultaCartera.balance))+" ")])],1):_vm._e(),(f.type === 'select-document-type')?_c('div',[_c('validation-provider',{attrs:{"name":f.label,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":f.key,"value-field":"id","text-field":"description","state":errors.length > 0 ? false:null,"options":_vm.documentTypes},model:{value:(_vm.data[f.key]),callback:function ($$v) {_vm.$set(_vm.data, f.key, _vm._n($$v))},expression:"data[f.key]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(f.type === 'select-person-types')?_c('div',[_c('validation-provider',{attrs:{"name":f.label,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":f.key,"value-field":"id","state":errors.length > 0 ? false:null,"text-field":"desc","options":_vm.persontypes},model:{value:(_vm.data[f.key]),callback:function ($$v) {_vm.$set(_vm.data, f.key, _vm._n($$v))},expression:"data[f.key]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(f.type === 'select-genero')?_c('div',[_c('validation-provider',{attrs:{"name":f.label,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":f.key,"value-field":"id","text-field":"desc","state":errors.length > 0 ? false:null,"options":_vm.genretypes},model:{value:(_vm.data[f.key]),callback:function ($$v) {_vm.$set(_vm.data, f.key, _vm._n($$v))},expression:"data[f.key]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()])}),_c('b-button',{attrs:{"disabled":_vm.validateButton,"variant":"primary mt-3"},on:{"click":function($event){return _vm.confirm()}}},[_vm._v(" Confirmar ")])],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }